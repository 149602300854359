<template>
  <div>
    <!-- Menu::Start -->
    <div class="container mt-4">
      <!-- Breadcrumb::End -->
      <div class="mt-auto">
        <!-- <div class="card">
          <div class="card-body" style="min-height: 360px; border-radius: 10px"> -->
        <div class="row" v-if="news.yt_link == ''">
          <div
            class="col-md-4 d-flex justify-content-center"
            style="width: 100%; height: 100%"
          >
            <img
              style="width: 100%; height: 100%; object-fit: contain"
              :src="news.image"
              alt="Gambar Desa"
            />
          </div>
          <div class="col-md-8">
            <div class="row p-0">
              <div class="col-md-12">
                <h2 class="font-weight-bold">{{ news.title }}</h2>
              </div>
              <div class="col-md-12">
                <p v-html="news.content" style="font-size: 20px;"></p>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="news.yt_link != ''">
          <div class="col-md-12 d-flex justify-content-center">
            <iframe
              width="100%"
              height="500"
              :src="news.yt_link"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div class="col-md-12">
            <div class="row p-0">
              <div class="col-md-12">
                <h2 class="font-weight-bold">{{ news.title }}</h2>
              </div>
              <div class="col-md-12">
                <p v-html="news.content" style="font-size: 20px;"></p>
              </div>
            </div>
            <!-- </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { setConfiguration } from "@/core/services/jwt.service.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    id: Number,
  },
  data() {
    return {
      news: {},
      config: {},
      interval: null,
      time: null,

      modal2: false,
      hamlets: [],
    };
  },
  methods: {
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      console.log(
        "get setting no auth ============================================================================================"
      );
      if (response != null) {
        this.config = response;
        setConfiguration(JSON.stringify(response));
      }
    },
    async getNews() {
      let response = await module.get("news/" + this.id);
      if (response != null) {
        this.news = response;
        console.log(this.news, "this.news");
      }
    },
  },
  mounted() {
    this.getSetting();
    this.getNews();
    // this.getHamletHeadOption();
  },
  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat("en-GB", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style scoped>
.curved-header {
  height: 270px;
  width: 1280px;
  border: none;
  -webkit-box-shadow: inset 0px -1px 0px black;
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px -28px 50px -21px rgba(156, 127, 156, 1);
}

.curved-footer {
  height: 230px;
  width: 1280px;
  border: none;
  /* -webkit-box-shadow: inset 0px -1px 0px black; */
  box-shadow: inset 0px -1px 0px black;
  margin-bottom: 4px;
  background: white;
  -webkit-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  -moz-box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
  box-shadow: inset 0px 28px 50px -21px rgba(156, 127, 156, 1);
}

.sample1 {
  border-radius: 0% 0% 150% 150%/0% 0% 70% 70%;
  width: 100%;
}
.sample2 {
  border-radius: 70% 70% 0% 0%/30% 30% 100% 100%;
  position: absolute;
  bottom: -5px;
  width: 100%;
}

.form-control-lg {
  height: 70px;
}
</style>

<style scoped>
.vue-treeselect--single .vue-treeselect__input-container {
  font-size: inherit;
  height: 70px !important;
}

.vue-treeselect--single .vue-treeselect__input {
  width: 100%;
  height: 70px !important;
}
</style>
